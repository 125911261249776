<template>
  <div class="surveys-tab surveys-detail-table">
    <div class="surveys-detail-table__head">
      <div class="surveys-detail-table__head-item typo-body-m">
        Тема
      </div>
      <div class="surveys-detail-table__head-item typo-body-m">
        Статус
      </div>
      <div class="surveys-detail-table__head-item typo-body-m">
        Дата запуска опроса
      </div>
      <div class="surveys-detail-table__head-item typo-body-m">
        Дата завершения
      </div>
      <div class="surveys-detail-table__head-item typo-body-m" />
    </div>
    <div class="surveys-detail-table__list">
      <template v-if="surveys && surveys.length">
        <div
          class="surveys-detail-table__item"
          v-for="survey in surveys"
          :key="survey.id"
          @click="goToDetail(survey.id)"
        >
          <div
            class="surveys-detail-table__hat"
            :style="{ backgroundColor: getStatus(survey.state).color }"
          />
          <div class="surveys-detail-table__item-inner">
            <div class="surveys-detail-table__theme typo-body-m">
              <div
                v-if="survey.priority"
                class="surveys-detail-table__priority"
                :style="{'color': getPriorityColor(survey.priority) }"
              >
                <esmp-icon :name="`priority-${survey.priority.name}`" />
              </div>
              <span>
                {{ survey.title }}
              </span>
            </div>
            <div class="surveys-detail-table__status">
              <esmp-tag
                :color="getStatus(survey.state).color"
                :is-status="true"
                class="typo-caption"
              >
                {{ getStatus(survey.state).name }}
              </esmp-tag>
            </div>
            <div class="surveys-detail-table__created-at typo-body-m">
              {{ dateFormat(survey.startDatetime, 'DD.MM.YYYY') }}
            </div>
            <div class="surveys-detail-table__deadline typo-body-m">
              {{ dateFormat(survey.finishDatetime, 'DD.MM.YYYY') }}
            </div>
            <div class="surveys-detail-table__actions typo-caption">
              <esmp-button
                view="function"
                class="surveys-detail-table__action"
              >
                Детали
              </esmp-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        Список пуст
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dateFormat from '@/filters/dateFormat';
import PRIORITY_COLORS_MAP from '@/pages/Ticket/helpers/priority.colors';
import { getStatus } from '@/helpers/status';

export default {
  name: 'Surveys',
  data() {
    return {
      surveys: [],
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    dateFormat,
    getStatus,
    getPriorityColor(priority) {
      if (!priority) return null;
      return priority.color || PRIORITY_COLORS_MAP[priority.name];
    },
    goToDetail(id) {
      this.$router.push({
        name: 'SurveyDetail',
        params: { id },
      });
    },
    getSurveys() {
      this.setLoading({ key: 'page', value: true });
      this.$API.survey.getSurveyChecklists()
        .then(({ data }) => {
          this.surveys = data;
        }).finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
  },
  mounted() {
    this.getSurveys();
  },
};
</script>

<style lang="scss">
.surveys-detail-table {
  &__head {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: $base-gutter / 1.5;
    padding: 0px 27px 8px;
  }
  &__head-item {
    color: $color-black-op-50;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: $base-gutter / 1.5;
  }
  &__actions {
    text-align: center;
  }
  &__item {
    position: relative;

    &:hover {
      box-shadow: 0px 0px 20px rgba(28, 41, 61, 0.05), 0px 12px 20px rgba(28, 41, 61, 0.06);
      transition: box-shadow $base-animation;
    }
  }
  &__item-inner {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 10px 27px;
    background-color: $color-white;
    border-radius: $base-border-radius;
    font-weight: 500;
  }
  &__hat {
    position: absolute;
    top: 0;
    left: -8px;
    height: 32px;
    width: 24px;
    border-radius: $base-border-radius;
    z-index: 1;
    opacity: .25;
  }
  &__theme {
    position: relative;
    display: grid;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  &__priority {
    position: absolute;
    top: 2px;
    left: -16px;
    .esmp-svg-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__action {
    color: $color-black;
    font-weight: 500;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;

    .esmp-user-avatar {
      flex-shrink: 0;
    }
  }
}
</style>
